import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { TeaserCard } from '../molecules/TeaserCard/TeaserCard';
import correctImageUrl from '../../helper/correctCMSUrl';
import { useCMSHomePage } from '../../hooks/api/gql/cms/useCMSHomePage';

export interface IHomeCardsTeaser {
    numberToShow: number;
}

export const HomeCardsTeaser: React.FC<IHomeCardsTeaser> = (props) => {
    const { numberToShow = 3 } = props;

    const { data: homeData } = useCMSHomePage();

    // Slice the cards to show only the number of cards defined in the props
    const cardsToShow = useMemo(() => {
        return homeData?.cards?.slice(0, numberToShow);
    }, [homeData?.cards, numberToShow]);

    return (
        <>
            {cardsToShow &&
                cardsToShow.map((item) => {
                    return (
                        <div className="col-span-1" key={uuidv4()}>
                            <TeaserCard
                                bgImage={correctImageUrl(item.image ?? '') ?? ''}
                                title={item.title}
                                text={item.content ?? ''}
                                ctaLink={item.ctaLink}
                                ignoreBasePath={true}
                            />
                        </div>
                    );
                })}
        </>
    );
};
